import {
  Box,
  Fade,
  Modal,
  styled,
  IconButton
} from '@mui/material'
import { Cancel } from '@mui/icons-material'

const ModalStyled = styled(Modal)(({ theme }) => ({
  width: '70%',
  height: '90vh',
  top: '5%',
  left: 0,
  marginLeft: '15%',
  position: 'absolute',
  [theme.breakpoints.down('sm')]: {
    width: '100%',
    height: '70%',
    top: '2%',
    left: 0,
    marginLeft: 0,
  },
  [theme.breakpoints.between('sm', 'md')]: {
    top: '2%',
    width: '85%',
    height: '90vh',
    marginLeft: '7.5%'
  },
  [theme.breakpoints.between('md', 'lg')]: {
    top: '2%',
    width: '100%',
    height: '90vh',
    marginLeft: 0
  },
}))
const BoxStyled = styled(Box)(() => ({
  position: 'absolute'
}))

const ModalImage = ({ img, openModal, closeModal }) => {
  return (
    <ModalStyled
      open={openModal}
      onClose={closeModal}
    >
      <Fade in={openModal}>
        <Box sx={{ width: '100%', height: '100%' }}>
          <BoxStyled>
            <IconButton onClick={closeModal} >
              <Cancel fontSize='large' />
            </IconButton>
          </BoxStyled>
          <img alt='Imagen' src={img} width={'100%'} height={'100%'} />
        </Box>
      </Fade>
    </ModalStyled>
  )
}

export default ModalImage
