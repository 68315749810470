import { createSlice, current } from '@reduxjs/toolkit'

import Data from '../Data/Productos.json'

const initialState = {
  linea: [],
  colors: [],
  categorias: [],
  pagination: { pageNum: 1, pageSize: 10 },
  filtros: {
    linea: 0,
    color: 0,
    category: 0,
  },
  loading: false,
  productos: Data,
  allProducts: null,
  pagProducts: null,
}

export const CatalogoSlice = createSlice({
  name: 'catalogo',
  initialState,
  reducers: {
    setLoading: state => {
      state.loading = true
    },
    loadProducts: state => {
      const values = Object.entries(Data)
      const colors = []
      const categorias = []
      const productos = []

      for (const [linea, lineaData] of values) {
        for (const bolsa of lineaData) {
          productos.push({ linea, ...bolsa })
          if (!colors.includes(bolsa.color)) colors.push(bolsa.color)
          if (!categorias.includes(bolsa.category))
            categorias.push(bolsa.category)
        }
      }

      state.pagination = {
        pageNum: 1,
        pageSize: 10,
        totalCount: productos.length,
        totalPages: Math.ceil(productos.length / 10),
      }
      state.categorias = categorias
      state.colors = colors
      state.linea = Object.keys(Data)
      state.allProducts = productos
      state.pagProducts = productos
      state.productos = productos.slice(0, state.pagination.pageSize)
      state.loading = false
    },
    applyFilters: (state, payload) => {
      const { linea, color, category } = payload.payload

      if (linea === 0 && color === 0 && category === 0) {
        state.pagination = {
          pageNum: 1,
          pageSize: 10,
          totalCount: state.allProducts ? state.allProducts.length : 0,
          totalPages: state.allProducts
            ? Math.ceil(state.allProducts.length / 10)
            : 0,
        }
        state.productos = state.allProducts
          ? state.allProducts.slice(0, state.pagination.pageSize)
          : []
        state.pagProducts = state.allProducts
      } else {
        let filtered = state.allProducts

        if (!!linea) filtered = filtered.filter(bolsa => bolsa.linea === linea)
        if (!!color) filtered = filtered.filter(bolsa => bolsa.color === color)
        if (!!category)
          filtered = filtered.filter(bolsa => bolsa.category === category)

        state.pagination = {
          pageNum: 1,
          pageSize: 10,
          totalCount: filtered.length,
          totalPages: Math.ceil(filtered.length / 10),
        }
        state.productos = filtered.slice(0, state.pagination.pageSize)
        state.pagProducts = filtered
      }

      state.loading = false
    },
    changePagination: (state, payload) => {
      const { pag } = payload.payload
      const prods = current(state).pagProducts
      let inicio = 0,
        fin = 10

      if (pag > 1) {
        inicio = (pag - 1) * state.pagination.pageSize
        fin = pag * state.pagination.pageSize
      }

      state.pagination = {
        ...state.pagination,
        pageNum: pag,
      }
      state.productos = prods.slice(inicio, fin)
      state.loading = false
    },
  },
})

export const { setLoading, loadProducts, applyFilters, changePagination } =
  CatalogoSlice.actions
export default CatalogoSlice.reducer
