import React from 'react'
import { Provider } from 'react-redux'
import ReactDOM from 'react-dom/client'
import reportWebVitals from './reportWebVitals'
import { BrowserRouter } from 'react-router-dom'
import { CssBaseline, ThemeProvider } from '@mui/material'

import theme from './Theme/Theme'
import AppRoutes from './Routes/Routes'
import Menu from './Components/Menu/Menu'

import { setupStore } from './App/Store'

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <React.StrictMode>
    <Provider store={setupStore()}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <BrowserRouter>
          <Menu>
            <AppRoutes />
          </Menu>
        </BrowserRouter>
      </ThemeProvider>
    </Provider>
  </React.StrictMode>,
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
