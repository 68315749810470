import { createTheme } from '@mui/material/styles'

const theme = createTheme({
  components: {
    MuiFormLabel: {
      styleOverrides: {
        asterisk: { color: '#EF6C00' },
      },
    },
  },
  typography: {
    fontFamily: ['Arvo', 'serif'].join(','),
    fontFamilyBody: ['Arvo', 'serif'].join(','),
    fontFamilyTitle: ['Merriweather Sans', 'sans-serif'].join(','),
    fontFamilyTitleCurve: ['Source Serif Pro', 'serif'].join(','),
  },
  palette: {
    primary: {
      main: '#FCB3B3',
      dark: '#fd8989',
      light: '#ffafaf',
      lighter: '#ffc2c2',
    },
    secondary: {
      main: '#0D267E',
      dark: '#061d6f',
      light: '#2442ad',
      lighter: '#3358dc',
    },
    gradient: {
      main: 'linear-gradient(#e66465, #9198e5)',
      footer: 'linear-gradient(0.6turn, #2442ad, #ffafaf, #3358dc);',
    },
  },
})

export default theme
