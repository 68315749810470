import { useEffect, useState } from 'react'
import {
  Chip,
  Card,
  styled,
  CardMedia,
  Typography,
  CardContent,
} from '@mui/material'
import ModalImage from './ModalImage'

const CardStyled = styled(Card)(({ theme }) => ({
  minWidth: '18rem',
  maxWidth: '18rem',
  height: '28rem',
  margin: 15,
  [theme.breakpoints.down('md')]: {
    margin: 5,
    width: '45%',
    minWidth: '45%',
  }
}))
const ContentStyled = styled(CardContent)(() => ({
  height: '22%',
  display: 'flex',
  paddingTop: 0,
  paddingBottom: 0,
  marginTop: 2,
  marginBottom: 2,
  alignItems: 'center',
  flexDirection: 'column',
  justifyContent: 'space-around',
}))

const CardItem = ({ item }) => {
  const [img, setImg] = useState(null)
  const [openModal, setOpenModal] = useState(false)

  useEffect(() => {
    const getImage = async () => {
      const res = await import(`../../static/images/products/${item.name}`)
      setImg(res.default)
    }
    getImage()
  })

  const descuento = item.descuento
  const isInteger = Number.isInteger(descuento)
  const precio = descuento
    ? (
      isInteger
      ? (item.price - descuento)
      : (item.price * (1 - descuento))
    )
    : item.price

  const openImage = () => setOpenModal(true)

  return (
    <>
      <CardStyled>
        <CardMedia
          component='img'
          height='75%'
          image={img}
          alt='Imagen de la bolsa'
          onClick={openImage}
        />
        <ContentStyled>
          {item.inStock && (
            <Chip label='Entrega inmediata' color='primary' sx={{ width: '90%' }} />
          )}
          <Typography>
            <span style={{ fontWeight: 800 }}>Precio:&nbsp;</span>
            {`$${precio} MXN`}&nbsp;&nbsp;
          </Typography>
          {!!descuento && (
            <Chip label='Oferta %' color='error' sx={{ width: '50%' }} />
          )}
        </ContentStyled>
      </CardStyled>
      <ModalImage
        img={img}
        openModal={openModal}
        closeModal={() => setOpenModal(false)}
      />
    </>
  )
}

export default CardItem
