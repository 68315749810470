import { useEffect } from 'react'
import { Route, Routes, useLocation } from 'react-router-dom'
import Home from '../Pages/Home'
import NotFound from '../Pages/NotFound'
import Catalogo from '../Pages/Catalogo'
import AvisoPrivacidad from '../Pages/Aviso'
import { useDispatch } from 'react-redux'
import { setLoading, loadProducts } from '../App/CatalogoSlice'

export const pages = [
  {
    name: 'Inicio',
    path: '/',
  },
  {
    name: 'Catalogo',
    path: '/Catalogo',
  },
]

const AppRoutes = () => {
  const dispatch = useDispatch()
  const { pathname } = useLocation()

  useEffect(() => {
    try {
      dispatch(setLoading())
      setTimeout(() => {
        dispatch(loadProducts())
      }, 500)
    } catch (error) {
      console.log('Error loading', error)
    }
  }, [dispatch])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])

  return (
    <Routes>
      <Route path={`${pages[0].path}`} element={<Home />} />
      <Route path={`${pages[1].path}`} element={<Catalogo />} />
      <Route path="/aviso-de-privacidad" element={<AvisoPrivacidad />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  )
}

export default AppRoutes
