import { useState, useEffect } from 'react'
import {
  Stack,
  Select,
  styled,
  Button,
  MenuItem,
  Pagination,
  Typography,
} from '@mui/material'
import { Clear } from '@mui/icons-material'
import { useDispatch } from 'react-redux'
import { setLoading, applyFilters, changePagination } from '../../App/CatalogoSlice'

const StackStyled = styled(Stack)(({ theme }) => ({
  width: '100%',
  marginTop: '1rem',
  marginBottom: '1rem',
  display: 'flex',
  flexDirection: 'row',
  flexFlow: 'row wrap',
  justifyContent: 'space-around',
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'colum',
    flexFlow: 'colum wrap',
    justifyContent: 'center',
    marginLeft: 5
  }
}))
const StackStyledPag = styled(Stack)(({ theme }) => ({
  width: '100%',
  marginTop: '0.5rem',
  marginBottom: '0.5rem',
  display: 'flex',
  alignItems: 'center'
}))
const SelectStyled = styled(Select)(({ theme }) => ({
  margin: '1rem',
  height: '3.5rem',
  width: '15rem',
  [theme.breakpoints.down('md')]: {
    flexGrow: 2,
    width: '8rem',
    margin: '0.5rem'
  },
  [theme.breakpoints.up('lg')]: {
    width: '28rem',
    margin: '1rem'
  }
}))
const ButtonStyled = styled(Button)(({ theme }) => ({
  height: '3.5rem',
  margin: '1rem',
  [theme.breakpoints.down('md')]: {
    flexGrow: 2,
    width: '8rem',
    margin: '0.5rem'
  },
  [theme.breakpoints.up('lg')]: {
    width: '28rem',
    margin: '1rem'
  }
}))

const Filtros = ({
  lineas,
  colors,
  categorias,
  pagination,
}) => {
  const dispatch = useDispatch()
  const [page, setPage] = useState(0)
  const [linea, setLinea] = useState(0)
  const [color, setColor] = useState(0)
  const [category, setCategory] = useState(0)

  useEffect(() => {
    dispatch(setLoading())
    dispatch(applyFilters({ linea, color, category }))
  }, [linea, color, category, dispatch])

  useEffect(() => {
    setPage(pagination.pageNum)
  }, [pagination])

  const clearFilters = () => {
    setLinea(0)
    setColor(0)
    setCategory(0)
  }

  const onChangePagination = (e, pag) => {
    dispatch(setLoading())
    dispatch(changePagination({ pag }))
  }

  return (
    <>
      <Typography sx={{ fontSize: '1.5rem' }}>Filtros</Typography>
      <StackStyledPag>
        <Pagination
          count={pagination.totalPages}
          page={page}
          shape='rounded'
          variant='outlined'
          showLastButton={true}
          showFirstButton={true}
          onChange={onChangePagination}
        />
      </StackStyledPag>
      <StackStyled>
        {(categorias && categorias.length > 0) && (
          <SelectStyled
            label='Categoria'
            value={category}
            variant='filled'
            onChange={e => {
              e.preventDefault()
              setCategory(e.target.value)
            }}
          >
            <MenuItem key={-1} value={0}>Seleccionar categoría</MenuItem>
            {categorias.map((it, idx) => (
              <MenuItem key={idx} value={it}>
                {it.toUpperCase()}
              </MenuItem>
            ))}
          </SelectStyled>
        )}
        {(lineas && lineas.length > 0) && (
          <SelectStyled
            label='Linea'
            value={linea}
            variant='filled'
            onChange={e => {
              e.preventDefault()
              setLinea(e.target.value)
            }}
          >
            <MenuItem key={-1} value={0}>Seleccionar linea</MenuItem>
            {lineas.map((it, idx) => (
              <MenuItem key={idx} value={it}>
                {it.toUpperCase()}
              </MenuItem>
            ))}
          </SelectStyled>
        )}
        {(colors && colors.length > 0) && (
          <SelectStyled
            label='Color'
            value={color}
            variant='filled'
            onChange={e => {
              e.preventDefault()
              setColor(e.target.value)
            }}
          >
            <MenuItem key={-1} value={0}>Seleccionar color</MenuItem>
            {colors.map((it, idx) => (
              <MenuItem key={idx} value={it}>
                {it.toUpperCase()}
              </MenuItem>
            ))}
          </SelectStyled>
        )}
        <ButtonStyled
          variant='contained'
          startIcon={<Clear />}
          onClick={clearFilters}
        >
          Limpiar filtros
        </ButtonStyled>
      </StackStyled>
    </>
  )
}

export default Filtros
