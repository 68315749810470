import {
  Box,
  styled,
  Container,
  Typography
} from '@mui/material'
import { NavLink } from 'react-router-dom'

import image from '../static/images/404.png'

const NaveStyled = styled(NavLink)(({ theme }) => ({
  textDecoration: 'none',
  margin: ' 1rem',
  fontWeight: theme.typography.fontWeightBold,
  fontSize: theme.typography.h2.fontSize,
  [theme.breakpoints.down('md')]: {
    fontSize: theme.typography.body1.fontSize
  },
  [theme.breakpoints.between('md', 'lg')]: {
    fontSize: 30
  }
}))
const ContainerStyled = styled(Container)(({ theme }) => ({
  width: '100%',
  maxWidth: '100%',
  display: 'flex',
  paddingRight: 0,
  marginTop: 20,
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  textAlign: 'center',
  [theme.breakpoints.up('md')]: {
    padding: 0,
  },
  [theme.breakpoints.down('md')]: {
    padding: 0,
  }
}))
const Typo = styled(Typography)(({ theme }) => ({
  fontSize: theme.typography.h3.fontSize,
  fontWeight: theme.typography.fontWeightBold,
  [theme.breakpoints.down('md')]: {
    fontSize: theme.typography.body1.fontSize
  },
  [theme.breakpoints.between('md', 'lg')]: {
    fontSize: 30
  }
}))
const BoxStyled = styled(Box)(() => ({
  left: '10%',
  position: 'absolute'
}))

const NotFound = () => {

  return (
    <ContainerStyled>
      <img alt='pagina-no-encontreda' src={image} width={'100%'} />
      <BoxStyled>
        <Typo>¡Oops!</Typo>
        <Typo>Página no encontrada</Typo>
        <Typo>
          <NaveStyled to='/'>
            ¡Vamos al inicio!
          </NaveStyled>
        </Typo>
      </BoxStyled>
    </ContainerStyled>
  )
}

export default NotFound
