import { Box, Stack, styled, Typography } from '@mui/material'
import Carousel from '../Components/Carousel/Carousel'
import Carousel1 from '../static/images/SPRING23_Slider.webp'
import Carousel2 from '../static/images/ULTIMAS-PIEZAS_SLIDER_FULL_5.webp'
import BannerFooter from '../static/images/Banner-footer-temporada-vigente-Ene.webp'

import Media1 from '../static/images/Banner-best-sellers_2.webp'
import Media2 from '../static/images/Banner-Nuevas-Colecciones_1.webp'

const BoxStyled = styled(Box)(() => ({
  width: '100%',
  height: '100%',
  marginLeft: 0,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  textAlign: 'center',
}))
const StackStyled = styled(Stack)(() => ({
  marginTop: '2rem',
  display: 'flex',
  width: '100%',
  flexDirection: 'column',
  justifyContent: 'space-between',
}))
const StackForTwo = styled(Stack)(() => ({
  width: '100%',
  flexDirection: 'row',
  display: 'inline-flex',
}))

const Home = () => {
  return(
    <BoxStyled>
      <StackStyled spacing={3} direction='column'>
        <Carousel items={[Carousel1, Carousel2]} />
        <Typography variant='body1'>
          Con nosotros podrás encontrar las mejores bolsas y accesorios para cualquier ocasión.
        </Typography>
        <StackForTwo>
          <img alt='media-1' src={Media1} width='50%' />
          <img alt='media-2' src={Media2} width='50%' />
        </StackForTwo>
        <img alt='Banner footer' src={BannerFooter} width='100%' />
      </StackStyled>
    </BoxStyled>
  )
}

export default Home
