import { useState } from 'react'
import {
  Box,
  Grid,
  Menu,
  AppBar,
  styled,
  Toolbar,
  Tooltip,
  MenuItem,
  useTheme,
  Container,
  IconButton,
} from '@mui/material'
import { NavLink } from 'react-router-dom'
import MenuIcon from '@mui/icons-material/Menu'
import WhatsApp from '@mui/icons-material/WhatsApp'
import Facebook from '@mui/icons-material/Facebook'
import Instagram from '@mui/icons-material/Instagram'
import './Menu.css'
import Footer from '../Footer'
import { pages } from '../../Routes/Routes'
import logoSmall from '../../static/images/Logo_small.png'

const NaveStyled = styled(NavLink)(({ theme }) => ({
  margin: ' 1rem',
  fontSize: '1.2rem',
  textDecoration: 'none',
  color: theme.palette.grey.A100,
  fontWeight: theme.typography.fontWeightBold,
}))
const NaveStyledImg = styled(NavLink)(() => ({
  position: 'absolute',
  right: 0,
  marginTop: 25
}))
const ContainerStyled = styled(Container)(({ theme }) => ({
  marginTop: '5rem',
  width: '100%',
  marginBottom: '2rem',
  marginLeft: 0,
  marginRight: 0,
  [theme.breakpoints.down('md')]: {
    maxWidth: '100%',
    paddingLeft: 0,
    paddingRight: 0
  },
  [theme.breakpoints.up('md')]: {
    maxWidth: '100%',
    paddingLeft: 0,
    paddingRight: 0,
  },
  [theme.breakpoints.up('md')]: {
    maxWidth: '100%',
    paddingLeft: 0,
    paddingRight: 0
  }
}))

const APPMenu = ({ children }) => {
  const theme = useTheme()
  const [anchorElNav, setAnchorElNav] = useState(null)

  const handleCloseNavMenu = () => setAnchorElNav(null)

  const SocialIcons = () => (
    <>
      <Tooltip title='WhatsApp' >
        <a href='https://wa.me/message/2RIVQQH7PYYZC1' target='_blank' rel="noreferrer">
          <IconButton color='success' >
            <WhatsApp  sx={{ fontSize: 30 }} />
          </IconButton>
        </a>
      </Tooltip>
      <Tooltip title='Facebook' >
        <a href='https://www.facebook.com/people/BR-bolsas-y-accesorios/100090542649594/' target='_blank' rel="noreferrer">
          <IconButton>
            <Facebook  sx={{ fontSize: 30, color: '#1877f2' }} />
          </IconButton>
        </a>
      </Tooltip>
      <Tooltip title='Instagram' >
        <a href='https://instagram.com/br.bolsas.y.accesorios?igshid=ZDdkNTZiNTM=' target='_blank' rel="noreferrer">
          <IconButton>
            <Instagram  sx={{ fontSize: 30, borderRadius: '30%', background: 'radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%,#d6249f 60%,#285AEB 90%)' }} />
          </IconButton>
        </a>
      </Tooltip>
    </>
  )

  return (
    <Grid container id="gridCont" spacing={2}>
      <AppBar id='AppBar' position="fixed" sx={{ width: '100%', height: '6rem' }}>
        <Container maxWidth="xl">
          <Toolbar disableGutters sx={{ display: { xs: 'none', md: 'flex' }}}>
            <NavLink to={'/'}>
              <img alt='Logo' src={logoSmall} width={150} height={70} style={{ marginTop: 8 }} />
            </NavLink>
            <Box sx={{ flexGrow: 1 }}>
              {pages.map(page => (
                <NaveStyled
                  key={page.name}
                  to={`${page.path}`}
                  style={({ isActive }) =>
                    isActive
                      ? { color: theme.palette.secondary.main }
                      : undefined
                  }
                >
                  {page.name}
                </NaveStyled>
              ))}
            </Box>
            <Box>
              <SocialIcons />
            </Box>
          </Toolbar>

          <Toolbar
            disableGutters
            sx={{ display: { xs: 'flex', md: 'none' } }}
          >
            <IconButton
              size="large"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={event => setAnchorElNav(event.currentTarget)}
              color="inherit"
            >
              <MenuIcon sx={{ fontSize: 40 }} />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{ display: { xs: 'block', md: 'none' } }}
            >
              {pages.map((page) => (
                <MenuItem
                  key={page.name}
                  onClick={handleCloseNavMenu}
                >
                  <NaveStyled
                    key={page.name}
                    to={`${page.path}`}
                    style={({ isActive }) =>
                      isActive
                        ? { color: theme.palette.secondary.dark, fontWeight: 900 }
                        : { color: theme.palette.secondary.lighter, fontWeight: 900 }
                    }
                  >
                    {page.name}
                  </NaveStyled>
                </MenuItem>
              ))}
            </Menu>
            <NaveStyledImg to={'/'}>
              <img
                alt='Logo'
                src={logoSmall}
                width={150}
                height={70}
              />
            </NaveStyledImg>
            <Box>
              <SocialIcons />
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
      <ContainerStyled id='mainCointainer'>{children}</ContainerStyled>
      <Footer />
    </Grid>
  )
}

export default APPMenu
