import { Box, Stack, styled, Typography, Divider } from '@mui/material'
import { NavLink } from 'react-router-dom'

import { pages } from '../Routes/Routes'

const BoxStyled = styled(Box)(({ theme }) => ({
  width: '90%',
  marginLeft: '5%',
  display: 'flex',
  marginTop: '2rem',
  flexDirection: 'column',
  alignItems: 'center',
  textAlign: 'center',
}))
const TypoTitle = styled(Typography)(({ theme }) => ({
  fontFamily: theme.typography.fontFamilyTitle,
}))
const NaveStyled = styled(NavLink)(({ theme }) => ({
  color: theme.palette.primary.dark,
  textDecoration: 'none',
  fontWeight: theme.typography.fontWeightBold,
  fontSize: '1.2rem',
}))

const AvisoPrivacidad = () => {
  return (
    <BoxStyled>
      <Stack
        spacing={3}
        flexDirection='column'
        sx={{ width: '70%', paddingBottom: '5rem', paddingTop: '1rem' }}
      >
        <TypoTitle sx={{ fontSize: 35 }}>
          AVISO DE PRIVACIDAD
        </TypoTitle>
        <Divider variant='fullWidth' />
        <Typography sx={{ textAlign: 'justify' }}>
          En BR accesorios sabemos y reconocemos el valor de los datos personales, es por ello que el tratamiento de dicha información se
          realiza con riguroso cumplimiento a la normativa vigente, en particular lo establecido por Ley Federal de Protección de Datos
          Personales en Posesión de los particulares (en lo sucesivo la Ley), su reglamento y los lineamientos para el aviso de privacidad
          publicados y vigentes, por lo que ponemos a su disposición el presente. 
        </Typography>
        <TypoTitle>
          DATOS PERSONALES
        </TypoTitle>
        <Typography sx={{ textAlign: 'justify' }}>
          Recabamos sus datos personales cuando Usted nos los proporciona a través de nuestro sitio de Internet www.braccesorios.com.mx y
          utiliza nuestros servicios de contacto en línea; a través de este servicio de contacto en línea de nuestro sitio web recabamos:
          nombre, domicilio particular, teléfono particular, teléfono celular y correo electrónico.
        </Typography>
        <TypoTitle>
          FINALIDADES
        </TypoTitle>
        <Typography sx={{ textAlign: 'justify' }}>
          Sus datos personales recabados serán utilizados con las siguientes finalidades primarias: proveer y/o enviar productos y/o servicios contratados;
          ofrecerle productos y/o servicios de acuerdo con las necesidades que detectemos; informarle sobre cambios en nuestras políticas de privacidad y seguridad de la información;
          informar sobre cambios en productos y/o servicios; enviar recordatorios de pago; enviar propuestas de servicios y cotizaciones.
          De forma adicional en Chenson nos gustaría contar con su consentimiento para utilizar sus datos para las siguientes finalidades secundarias: informarle sobre nuevos productos y/o servicios;
          evaluar la calidad en el servicio; enviar promociones, invitaciones a eventos o conferencias relacionadas con bolsos y tendencias de moda.
          En caso de que Usted no desee que sus datos personales sean tratados para la finalidades secundarias antes descritas,
          Usted podrá contactarnos en cualquier momento y ejercer dicho derecho a través de nuestro correo electrónico braccesorios28@gmail.com.
        </Typography>
        <TypoTitle>
          SEGURIDAD
        </TypoTitle>
        <Typography sx={{ textAlign: 'justify' }}>
          BR accesorios almacenará sus datos en medios electrónicos y físicos, para los cuales ha implementado medidas de seguridad técnicas,
          físicas y administrativas con la finalidad de resguardar sus datos personales. Todo nuestro personal está consciente de la
          importancia de la seguridad de los datos y ha sido capacitado sobre la Ley Federal de Protección de Datos Personales.
          Del mismo modo, hacemos de su conocimiento que todo nuestro personal ha suscrito Convenios de Confidencialidad.
        </Typography>
        <Typography sx={{ textAlign: 'center' }}>
          <NaveStyled to={`/${pages[0]}`}>
            Ir a inicio
          </NaveStyled>
        </Typography>
      </Stack>
    </BoxStyled>
  )
}

export default AvisoPrivacidad
