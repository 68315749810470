import { Box, styled, Typography } from '@mui/material'
import { NavLink } from 'react-router-dom'

const NaveStyled = styled(NavLink)(({ theme }) => ({
  color: theme.palette.grey[200],
  textDecoration: 'none',
  fontWeight: theme.typography.fontWeightBold,
}))

const BoxStyled = styled(Box)(({ theme }) => ({
  width: '100%',
  height: '6rem',
  bottom: 0,
  left: 0,
  right: 0,
  justifyContent: 'space-between',
  alignItems: 'center',
  textAlign: 'center',
  padding: '1rem 0 0.5rem 0',
  backgroundColor: theme.palette.grey[800],
  opacity: 0.9
}))

const Footer = () => {
  return (
    <BoxStyled>
      <Typography sx={{ color: 'white' }}>
        Todos los derechos reservados.&nbsp; BR Accesorios &#8482;
      </Typography>
      <Typography sx={{ color: 'white' }}>
        <NaveStyled to={'/aviso-de-privacidad'}>
          Terminos y condiciones
        </NaveStyled>
      </Typography>
    </BoxStyled>
  )
}

export default Footer
