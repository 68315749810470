import { configureStore, combineReducers } from '@reduxjs/toolkit'

import CatalogoReducer from './CatalogoSlice'

const rootReducer = combineReducers({
  catalogo: CatalogoReducer,
})

export const setupStore = preloadedState => {
  return configureStore({
    reducer: rootReducer,
    preloadedState,
  })
}
