import { Stack, LinearProgress } from '@mui/material'

const LoadingBar = () => {
  return (
    <Stack mt={'2.1rem'} sx={{ width: '100%', height: '70vh' }}>
      <LinearProgress color='secondary' sx={{ height: 8 }} />
    </Stack>
  )
}

export default LoadingBar
