import { useState, useEffect } from 'react'
import {
  Box,
  Stack,
  styled,
} from '@mui/material'
import CardItem from '../Components/Catalogo/Card'
import LoadingBar from '../Components/Loading'
import Filtros from '../Components/Catalogo/Filtros'
import { useSelector } from 'react-redux'

const BoxStyled = styled(Box)(() => ({
  width: '100%',
  marginTop: '2.5rem',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  textAlign: 'center',
}))

const StackStyled = styled(Stack)(() => ({
  width: '100%',
  paddingLeft: 10,
  display: 'flex',
  flexDirection: 'row',
  flexFlow: 'row wrap',
  justifyContent: 'center',
}))

const Catalogo = () => {
  const [productos, setProductos] = useState([])
  const catalogoSlice = useSelector(state => state.catalogo)

  useEffect(() => {
    if (catalogoSlice && catalogoSlice.productos)
      setProductos(catalogoSlice.productos)
  }, [catalogoSlice])

  return (
    <>
      {(!catalogoSlice || catalogoSlice.loading) ? (
        <LoadingBar />
        ): (
        <BoxStyled>
          <Filtros
            lineas={catalogoSlice.linea}
            colors={catalogoSlice.colors}
            categorias={catalogoSlice.categorias}
            pagination={catalogoSlice.pagination}
          />
          <StackStyled>
            {(productos && Array.isArray(productos))
              ?
                productos.map((bolsa, idx) => <CardItem key={idx} item={bolsa} />)
              : null}
            </StackStyled>
        </BoxStyled>
      )}
    </>
  )
}

export default Catalogo
