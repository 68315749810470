import { useEffect, useState } from 'react'
import { styled, Fade, IconButton, Tooltip } from '@mui/material'
import {
  Circle,
  PlayArrow,
  PauseCircle,
  ArrowCircleLeft,
  ArrowCircleRight,
} from '@mui/icons-material'

const DivItem = styled('div')(({ theme }) => ({
  width: '100%',
  height: '43rem',
  display: 'inline-flex',
  [theme.breakpoints.down('md')]: {
    height: '25rem'
  }
}))

const CarouselItem = ({ children }) => {
  return (
    <DivItem className='carousel-item'>
      {children}
    </DivItem>
  )
}

const DivCarousel = styled('div')(() => ({
  width: '100%',
  overflow: 'hidden',
}))
const DivInner = styled('div')(() => ({
  width: '100%',
  whiteSpace: 'nowrap',
  transition: '0.35s'
}))
const DivButtons = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'center'
}))

const Carousel = ({ items }) => {
  const [paused, setPaused] = useState(false)
  const [activeIndex, setActiveIndex] = useState(0)

  const updateIndex = (newIndex) => {
    if (newIndex < 0) {
      newIndex = items.length - 1;
    } else if (newIndex >= items.length) {
      newIndex = 0;
    }
    setActiveIndex(newIndex);
  }

  useEffect(() => {
    const interval = setInterval(() => {
      if (!paused) {
        updateIndex(activeIndex + 1);
      }
    }, 3500)

    return () => {
      if (interval) clearInterval(interval)
    }
  })

  return (
    <DivCarousel className='carousel'>
      <DivInner
        className='inner'
        style={{ transform: `translateX(-${activeIndex * 100}%)` }}
      >
        {items.map((it, idx) => (
          <CarouselItem key={idx}>
            <img alt={`carousel item ${idx + 1}`} src={it} width={'100%'} />
          </CarouselItem>
        ))}
      </DivInner>
      <DivButtons>
        <IconButton
          color='primary'
          aria-label='fingerprint'
          onClick={() => updateIndex(activeIndex - 1)}
        >
          <ArrowCircleLeft />
        </IconButton>
        <IconButton
          color={paused ? 'secondary' : 'primary'}
          aria-label='fingerprint'
          onClick={() => setPaused(!paused)}
        >
          <Tooltip title='Pausar' TransitionComponent={Fade} TransitionProps={{ timeout: 600 }}>
          {paused ? <PlayArrow /> : <PauseCircle />}
          </Tooltip>
        </IconButton>
        {items.map((it, idx) => (
          <IconButton
            key={idx}
            color={idx === activeIndex ? 'secondary' : 'primary'}
            aria-label='fingerprint'
            onClick={() => updateIndex(idx)}
          >
            <Circle />
          </IconButton>  
        ))}
        <IconButton
          color='primary'
          aria-label='fingerprint'
          onClick={() => updateIndex(activeIndex + 1)}
        >
          <ArrowCircleRight />
        </IconButton>
      </DivButtons>
    </DivCarousel>
  )
}

export default Carousel
